<script setup lang="ts">
const { isMobile } = useDevice();
const { data: appInit } = useAppInitData();
const isGuest = useIsGuest();
const { platform, onClickDownloadApp } = useAppDownload();
const isShow = computed(() => {
	if (appInit.value?.showAppFeaturesForGuest) {
		return true;
	}
	return isMobile && !isGuest.value;
});
</script>
<template>
	<div v-if="isShow">
		<ADivider />
		<div class="app-download" data-tid="app-download">
			<NuxtIcon
				class="app-download-img"
				:name="`116/badge-${platform}`"
				alt="favorite-empty"
				filled
				@click="onClickDownloadApp()"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.app-download {
	padding: gutter(2) 0;

	&-img {
		cursor: pointer;

		&:deep(svg) {
			width: 96px;
			height: 32px;
		}
	}
}
</style>
